<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'freightCoupon',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/freightCoupon/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '店铺名称',
          key: 'shopName',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '券名',
          align: 'left'
        },
        {
          dataIndex: 'usedNum',
          title: '使用数量',
          align: 'left'
        },
        {
          dataIndex: 'giveNum',
          title: '发放数量',
          align: 'left'
        },
        {
          dataIndex: 'useStartTime',
          title: '有效期',
          align: 'left',
          customRender: (text, record) => {
            return `${moment(text).format('YYYY-MM-DD')}~${moment(record.useEndTime).format('YYYY-MM-DD')}`
          }
        },
        {
          dataIndex: 'shopList',
          title: '针对店铺',
          align: 'left',
          type: 'tagGroup',
          getData: records => (records.shopList.length > 0 ? records.shopList.map(e => e.shopName) : [])
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/freightCoupon/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '用户管理',
                onClick: () => {
                  this.$router.push({
                    path: `/marketingCenter/freightCouponUser`,
                    query: {
                      id: records.id
                    }
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push({
                    path: '/marketingCenter/freightCouponDetail',
                    query: {
                      id: records.id
                    }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/freightCoupon',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push({
              path: '/marketingCenter/freightCouponDetail'
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
